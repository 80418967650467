.contact-form-container {
  max-width: 600px;
  margin: 5px auto;
  padding: 50px;
  background-color: #0d64aa;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  outline: black;
  outline-style: solid;
  outline-color: black;
}

h2 {
  text-align: center;
  color: black;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  outline-color: black;
  outline-style: solid;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

label {
  background-color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  outline: black solid 4px;
  border-radius: 50px;
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #000;
}

input,
textarea {
  outline: black solid;
  width: 100%;
  margin-top: 5px;
  padding-top: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

textarea {
  height: 120px;
  resize: vertical;
}

button {
  background-color: #073255;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
  outline: black;
  outline-width: 4px;
  outline-style: so;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}

button:disabled {
  background-color: #0d64aa;
  cursor: not-allowed;
}

.success-message,
.error-message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
}

@media (max-width: 480px) {
  .contact-form-container {
    padding: 10px;
  }

  input,
  textarea,
  button {
    font-size: 14px;
  }
}