/* src/components/AboutUs.css */
.about-us {
    padding: 40px 20px;
    background-color: #073255;
}

.about-us h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #ffffff;
    background-color: #0d64aa;
    flex-wrap: wrap;
}

.about-content {
    display: flex;
    gap: 20px;
    box-shadow: rgba(0, 0, 0, 0.6);
    align-items: center;
}

.about-text {
    padding: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: larger;
    color: #ffffff;
    background-color: #0d64aa;
    border-radius: 20px;
    outline-style: solid;
    outline-color: black;
    margin-bottom: 20px;
    line-height: 1.85;
}

.about-image {
    display: block;
    margin: 20px;
    width: 200px;
    outline: black solid;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    align-items: center;
    background-color: #0d64aa;
}

@media (max-width: 768px) {
    .about-content {
        flex-direction: column;
    }
}